.table-container {
  margin: 20px auto;
  background-color: veolia-color('white');
}

thead {
  color: veolia-color('white');
  background-color: veolia-color('secondary');
}

tbody {
  background-color: veolia-color('white');
}
