.txt-grey {
    color: veolia-color('blackgray') !important;
}

.txt-m {
    font-size: 1.2em;
}

.txt-under {
    text-decoration: underline;
}

