html,
body {
  height: 100%;
  background-color: veolia-color('white', 50);
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif !important; //Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.content {
  width: 80%;
  margin: 25px auto;
}

.pointer {
  cursor: pointer;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-center {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: veolia-color('white', 50);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active,
.dot:hover {
  background-color: veolia-color('primary');
}


.block-white {
  background-color: veolia-color('white');
  padding: 20px;
}


.block-grey {
  background-color: veolia-color('default');
}

.centralised {
  text-align: center;
  padding: 50px;
}

.flex {
  display: flex;
  justify-content: center;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.mat-form-field,
.input-filter {
  width: 190px !important;
}

.mat-form-field-infix {
  padding: 0px !important;
}

.message {
  .mat-form-field-infix {
    .mat-form-field-label-wrapper {
      .mat-form-field-empty {
        margin-top: 8px;
        margin-left: 5px;
      }
    }
  }
}

.mat-form-field-label {
  font-size: 16px !important;
}

.text-with-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  .info-icon {
    width: 17px;
    height: 17px;
    font-size: 17px;
    color: #393939;
  }
}