.bg-grey {
    background-color: veolia-color('blackgray') !important;
}

.bg-primary {
    background-color: veolia-color('primary') !important;
}

.bg-white {
    background-color: veolia-color('white') !important;
}


