.background-white {
  background-color: veolia-color('white');
}
.border-dashed-bottom-gray {
  border-bottom: 1px dashed veolia-color('darkgray');
}
.border-top-gray {
  border-top: 2px solid veolia-color('darkgray');
}

.color-white {
  color: veolia-color('white');
}

.color-red {
  color: veolia-color('danger');
}

.background-primary {
  color: veolia-color('white');
  background-color: veolia-color('primary', 10);

  .mat-form-field-underline {
    background-color: veolia-color('white');
  }

  svg,
  .mat-select-arrow,
  .mat-select-value,
  .mat-form-field-label,
  .mat-input-element,
  .mat-input-element:disabled .mat-form-field-empty.mat-form-field-label {
    color: veolia-color('white') !important;
  }
}

.background-secondary {
  color: veolia-color('white');
  background-color: veolia-color('secondary');

  .mat-form-field-underline {
    background-color: veolia-color('white');
  }

  svg,
  .mat-select-arrow,
  .mat-select-value,
  .mat-form-field-label,
  .mat-input-element,
  .mat-input-element:disabled .mat-form-field-empty.mat-form-field-label {
    color: veolia-color('white') !important;
  }
}

.border-bottom-form {
  .mat-form-field-wrapper {
    padding-bottom: 2.2em !important;
  }
}
