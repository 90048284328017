.btn-primary {
  color: veolia-color('white');
  background-color: veolia-color('primary');
}

.btn-secondary {
  color: veolia-color('white');
  background-color: veolia-color('secondary');
}

.btn-black {
  color: veolia-color('white');
  background-color: veolia-color('black');
}

.btn-grey {
  color: veolia-color('white');
  background-color: veolia-color('darkgray');
}

.btn-red {
  color: veolia-color('white');
  background-color: veolia-color('danger');
}

.btn-white {
  color: veolia-color('black');
  background-color: veolia-color('white');
}

.curved-angles {
  border-radius: 0px 20px !important;
}

.curved-edges {
  border-radius: 25px !important;
}

button:focus {
  outline: none;
}
