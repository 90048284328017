/**
* Colors
*/
$veolia-colors: (
  'text': #5C5B5B,
  'black': #323232,
  'darkgray': #A9A9A9,
  'orange': #FF9372,
  'green': #50E3C2,
  'white': ('default': #ffffff,
    50: #EBEBEB),
  'blackgray': #656464,
  'primary': ('default': #0D47A1,
    10: #00AEC5,
    20: #00aed354,
    40: #00b6d09e,
    50: #00B6D0,
    60: #058cbe),
  'secondary': ('default': #0D47A1),
  'success': ('default': #4BB543,
    'light': #60D9B2),
  'danger': ('default': #EF3926,
    'light': #F69388),
  'warning': ('default': #EEC643),
  'yellow': ('default' : yellow),
  ) !default;

$veolia-breakpoints: (
  'XS': 340px,
  'S-min': 340px,
  'S': 769px,
  'M':991px,
  'L-min': 992px,
  'L': 1200px,
  'XL': 1400px) !default;

/**
* Easings
*/

$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1
);

/**
* Fonts
*/

$font-primary: 'Roboto',
  Helvetica,
  Arial,
  sans-serif;