header {
  background-color: veolia-color('secondary') !important;
}

.header_links {
  font-size: 16px !important;
  font-weight: normal !important;
  color: veolia-color('white');
  padding: 0 16px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: veolia-color('white');
  }
}
