/*!
 * CSS PROJECT files
 * Author : Darius
 */

// Core
@import "assets/scss/project/vendors/material";
/* @import "assets/scss/project/vendors/bootstrap"; */
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import 'ngx-toastr/toastr';

// Processed files (DON'T EDIT)
@import "assets/scss/processed/index";

// Project files (YOU CAN EDIT THOSE FILES)
@import "assets/scss/project/hermes";


$primary-color: #0D47A1;
$secondary-color: #666666;
$danger-color: #ff3838;
$warning-color: #f0b208;
$warning-bg-color: #f3eecf;
$success-color: #2ec946;


.input-filter {
  width: 259px;
  height: 60px;
}

.content-layout {
  display: flex !important;
  width: 80% !important;
  max-width: 950px !important;
}

.page-layout {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
}

.alert {
  width: 512px;
  padding: 16px;
  margin: 8px;
  max-width: 90%;
}

.color-blue {
  color: $primary-color;
}

.no-underline {
  text-decoration: none !important;
}

.main-title {
  font-family: Inter;
  font-weight: 700;
  font-size: 18px;
  margin: 15px 0;
}

.main-font {
  font-family: Inter;
  font-weight: 500;
}

// @mixin alert-2($color) {
//   border-left: 4px solid $color;
//   background-color: rgba($color, 0.05);

//   .alert-title {
//     color: $color;
//   }
// }

.alert-warning {
  // @include alert-2($warning-color);
  border-left: 4px solid $warning-color;
  background-color: $warning-bg-color;

  .alert-title {
    color: $warning-color;
  }

  .button-group {
    display: flex;
    justify-content: center;

    button {
      margin: 15px;
      padding: 8px;
      border: 1px solid $warning-color;
      border-radius: 5px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
    }

    .button-ok {
      background: $warning-color;
      color: white;
    }
  }
}

.alert-title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}

.alert-content {
  margin: 0;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  color: #555555;
}

.snack-bar-container {
  background: #2196F3;
}

.mat-select-trigger {
  height: 100%;
}

.mat-select-value {
  padding: 10px;
}

.ngx-slider-pointer:after {
  width: 14px !important;
  height: 14px !important;
  top: 9px !important;
  border-radius: 8px !important;
  background: #13b9f0 !important;
}

.ngx-slider-pointer {
  background-color: transparent !important;
}

.ngx-slider-bubble {
  color: #00aec7 !important;
}

.ngx-slider-limit {
  color: black !important;
}

.mat-radio-group {
  margin-top: 15px;
}

.ngx-timepicker {
  border: 1px solid #969696 !important;
  border-radius: 5px;
  width: 190px;
  height: 40px !important;
}

.ngx-timepicker__toggle {
  margin-left: 15px;
}

.ngx-timepicker-control__arrows {
  top: 3px !important;
}

.ngx-timepicker-control__arrow {
  font-size: 9px !important;
  color: black !important;
}

.ngx-timepicker-control__input {
  font-size: 16px !important;
  font-weight: bold;
  color: black;
}

.mat-form-field-flex {
  background-color: transparent !important;
  // border: 1px solid #969696 !important;
  border-radius: 5px;
  height: 40px;
  padding: 0px !important;
  font-size: 16px;
}

.mat-form-field-infix {
  bottom: 6px;
  left: 5px;
  font-size: 18px;
  height: 40px;
}

.timepicker-button {
  color: #0D47A1 !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-select-arrow-wrapper {
  padding-right: 5px;
}

.bdrop {
  background-color: #bbbbbbf2;
  backdrop-filter: blur(4px);
}


.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-menu-panel,
.mat-menu-content,
.mat-tab-body-content {
  overflow-y: hidden !important;
  overflow-x: hidden;
}

.mat-option-text {
  font-size: 18px;
}


.cdk-overlay-pane {
  min-width: 180px !important;
  margin-left: 35px;
}

.mat-date-range-input {
  font-size: .9em;
}

.toastr-link {
  color: #9d9dff !important;

  &:hover {
    color: #5d5dbe !important;
  }
}

.inter-font {
  font-family: 'Inter' !important;
}